// Property type
export const resiSalesPropertyType = [
    {label: "All Properties", value: ""},
    {label: "Apartment", value: "apartment"},
    {label: "House", value: "house"},
    {label: "Site", value: "site"},
    {label: "Investment", value: "investment"},
    {label: "Farm", value: "farm"},
]

export const resiLettingsPropertyType = [
    {label: "All Properties", value: ""},
    {label: "Apartment", value: "apartment"},
    {label: "Terraced House", value: "terraced-house"},
    // {label: "Semi-Detached", value: "semi-detached"},
    // {label: "Detached", value: "detached"},
    {label: "Bungalow", value: "bungalow"},
    // {label: "Country House", value: "country-house"},
    // {label: "Studio", value: "studio"},
    {label: "House", value: "house"},   
    // {label: "Penthouse", value: "penthouse"},
]

export const commercialSalesPropertyType = [
    {label: "All Properties", value: ""},
    {label: "Office", value: "office"},
    {label: "Industrial Units", value: "industrial-units"},
    {label: "Businesses", value: "business"},
    {label: "Investments Property", value: "investment-property"},
    {label: "Pubs & Restaurants", value: "pubs-&-restaurants"},
    {label: "Hotels & B+Bs", value: "hotels"},
    {label: "Retail Units", value: "retail-units"},
    {label: "Farm Lands", value: "farm-land"},
    {label: "Development Lands", value: "development-land"},
    {label: "Parking Spaces", value: "parking-space"},
    {label: "Live-Work Units", value: "live-work-units"},
]

export const newHomesSalesPropertyType = [
    {label: "All Properties", value: ""},
    {label: "Apartments", value: "apartment"},
    {label: "House", value: "house"},
    {label: "Site", value: "site"},
    {label: "Farm", value: "farm"},
]
// Property type

export const Styles = [
    {label: "All", value: ""},
    {label: "Detached", value: "detached"},
    {label: "End Terrace", value: "end-terrace"},
    {label: "Mews", value: "mews"},
    {label: "Penthouse", value: "penthouse"},
    {label: "Semi Detached", value: "semi-detached"},
    {label: "Studio", value: "studio"},
]
 

// Energy Rating Value
export const energyRating = [
    {label: "No Min. BER", value: 0},
    {label: "A1", value: 150},
    {label: "A2", value: 140},
    {label: "A3", value: 130},
    {label: "B1", value: 120},
    {label: "B2", value: 110},
    {label: "B3", value: 100},
    {label: "C1", value: 90},
    {label: "C2", value: 80},
    {label: "C3", value: 70},
    {label: "D1", value: 60},
    {label: "D2", value: 50},
    {label: "E1", value: 40},
    {label: "E2", value: 30},
    {label: "F", value: 20},
    {label: "G", value: 10},
]

// Price Min Residential Sales
export const priceminRangeResiSale = [
    {label: "No Min. Price", value: "0"},
    {label: "€25,000", value: "25000"},
    {label: "€50,000", value: "50000"},
    {label: "€75,000", value: "75000"},
    {label: "€100,000", value: "100000"},
    {label: "€125,000", value: "125000"},
    {label: "€150,000", value: "150000"},
    {label: "€175,000", value: "175000"},
    {label: "€200,000", value: "200000"},
    {label: "€220,000", value: "220000"},
    {label: "€225,000", value: "225000"},
    {label: "€250,000", value: "250000"},
    {label: "€275,000", value: "275000"},
    {label: "€300,000", value: "300000"},
    {label: "€350,000", value: "350000"},
    {label: "€400,000", value: "400000"},
    {label: "€450,000", value: "450000"},
    {label: "€500,000", value: "500000"},
    {label: "€550,000", value: "550000"},
    {label: "€600,000", value: "600000"},
    {label: "€650,000", value: "650000"},
    {label: "€700,000", value: "700000"},
    {label: "€800,000", value: "800000"},
    {label: "€900,000", value: "900000"},
    {label: "€1,000,000", value: "1000000"},
    {label: "€1,250,000", value: "1250000"},
    {label: "€1,500,000", value: "1500000"},
    {label: "€1,750,000", value: "1750000"},
    {label: "€2,000,000", value: "2000000"},
    {label: "€2,250,000", value: "2250000"},
    {label: "€2,500,000", value: "2500000"},
    {label: "€2,750,000", value: "2750000"},
    {label: "€3,000,000", value: "3000000"},
    {label: "€4,000,000", value: "4000000"},
    {label: "€5,000,000", value: "5000000"},
]
// Price Min Residential Sales
  
// Price Max Residential Sales
export const pricemaxRangeResiSale = [
    {label: "No Max. Price", value: "0"},
    {label: "€5,000,000 +", value: "5000000"},
    {label: "€4,000,000", value: "4000000"},
    {label: "€3,000,000", value: "3000000"},
    {label: "€2,750,000", value: "2750000"},
    {label: "€2,500,000", value: "2500000"},
    {label: "€2,250,000", value: "2250000"},
    {label: "€2,000,000", value: "2000000"},
    {label: "€1,750,000", value: "1750000"},
    {label: "€1,500,000", value: "1500000"},
    {label: "€1,250,000", value: "1250000"},
    {label: "€1,000,000", value: "1000000"},
    {label: "€900,000", value: "900000"},
    {label: "€800,000", value: "800000"},
    {label: "€700,000", value: "700000"},
    {label: "€650,000", value: "650000"},
    {label: "€600,000", value: "600000"},
    {label: "€550,000", value: "550000"},
    {label: "€500,000", value: "500000"},
    {label: "€450,000", value: "450000"},
    {label: "€400,000", value: "400000"},
    {label: "€350,000", value: "350000"},
    {label: "€300,000", value: "300000"},
    {label: "€275,000", value: "275000"},
    {label: "€250,000", value: "250000"},
    {label: "€225,000", value: "225000"},
    {label: "€220,000", value: "220000"},
    {label: "€200,000", value: "200000"},
    {label: "€175,000", value: "175000"},
    {label: "€150,000", value: "150000"},
    {label: "€125,000", value: "125000"},
    {label: "€100,000", value: "100000"},
    {label: "€75,000", value: "75000"},
    {label: "€50,000", value: "50000"},
    {label: "€25,000", value: "25000"},
]
// Price Max Residential Sales
  
// Price Min Residential Lettings
export const priceminRangeResiRent = [
    {label: "No Min. Price", value: "0"},
    {label: "€400", value: "400"},
    {label: "€450", value: "450"},
    {label: "€500", value: "500"},
    {label: "€550", value: "550"},
    {label: "€600", value: "600"},
    {label: "€650", value: "650"},
    {label: "€700", value: "700"},
    {label: "€750", value: "750"},
    {label: "€800", value: "800"},
    {label: "€850", value: "850"},
    {label: "€900", value: "900"},
    {label: "€950", value: "950"},
    {label: "€1,000", value: "1000"},
    {label: "€1,100", value: "1100"},
    {label: "€1,200", value: "1200"},
    {label: "€1,300", value: "1300"},
    {label: "€1,400", value: "1400"},
    {label: "€1,500", value: "1500"},
    {label: "€1,600", value: "1600"},
    {label: "€1,700", value: "1700"},
    {label: "€1,800", value: "1800"},
    {label: "€1,900", value: "1900"},
    {label: "€2,000", value: "2000"},
    {label: "€2,100", value: "2100"},
    {label: "€2,200", value: "2200"},
    {label: "€2,300", value: "2300"},
    {label: "€2,400", value: "2400"},
    {label: "€2,500", value: "2500"},
    {label: "€2,600", value: "2600"},
    {label: "€2,700", value: "2700"},
    {label: "€2,800", value: "2800"},
    {label: "€2,900", value: "2900"},
    {label: "€3,000", value: "3000"},
    {label: "€3,500", value: "3500"},
    {label: "€4,000", value: "4000"},
    {label: "€4,500", value: "4500"},
    {label: "€5,000", value: "5000"},
    {label: "€5,500", value: "5500"},
    {label: "€6,000", value: "6000"},
    {label: "€6,500", value: "6500"},
    {label: "€7,000", value: "7000"},
    {label: "€7,500", value: "7500"},
    {label: "€8,000", value: "8000"},
    {label: "€8,500", value: "8500"},
    {label: "€9,000", value: "9000"},
    {label: "€9,500", value: "9500"},
    {label: "€10,000", value: "10000"},
]
// Price Min Residential Lettings
  
// Price Max Residential Lettings
export const pricemaxRangeResiRent = [
    {label: "No Max. Price", value: "0"},
    {label: "€10,000 +", value: "10000"},
    {label: "€9,500", value: "9500"},
    {label: "€9,000", value: "9000"},
    {label: "€8,500", value: "8500"},
    {label: "€8,000", value: "8000"},
    {label: "€7,500", value: "7500"},
    {label: "€7,000", value: "7000"},
    {label: "€6,500", value: "6500"},
    {label: "€6,000", value: "6000"},
    {label: "€5,500", value: "5500"},
    {label: "€5,000", value: "5000"},
    {label: "€4,500", value: "4500"},
    {label: "€4,000", value: "4000"},
    {label: "€3,500", value: "3500"},
    {label: "€3,000", value: "3000"},
    {label: "€2,900", value: "2900"},
    {label: "€2,800", value: "2800"},
    {label: "€2,700", value: "2700"},
    {label: "€2,600", value: "2600"},
    {label: "€2,500", value: "2500"},
    {label: "€2,400", value: "2400"},
    {label: "€2,300", value: "2300"},
    {label: "€2,200", value: "2200"},
    {label: "€2,100", value: "2100"},
    {label: "€2,000", value: "2000"},
    {label: "€1,900", value: "1900"},
    {label: "€1,800", value: "1800"},
    {label: "€1,700", value: "1700"},
    {label: "€1,600", value: "1600"},
    {label: "€1,500", value: "1500"},
    {label: "€1,400", value: "1400"},
    {label: "€1,300", value: "1300"},
    {label: "€1,200", value: "1200"},
    {label: "€1,100", value: "1100"},
    {label: "€1,000", value: "1000"},
    {label: "€950", value: "950"},
    {label: "€900", value: "900"},
    {label: "€850", value: "850"},
    {label: "€800", value: "800"},
    {label: "€750", value: "750"},
    {label: "€700", value: "700"},
    {label: "€650", value: "650"},
    {label: "€600", value: "600"},
    {label: "€550", value: "550"},
    {label: "€500", value: "500"},
    {label: "€450", value: "450"},
    {label: "€400", value: "400"},
]
// Price Max Residential Lettings
  
// Bedrooms
export const bedroomsRange = [
    {label: "Bedrooms", value: ""},
    {label: "Studio", value: "0"},
    {label: "1+", value: "1"},
    {label: "2+", value: "2"},
    {label: "3+", value: "3"},
    {label: "4+", value: "4"},
    {label: "5+", value: "5"},
    {label: "6+", value: "6"},
    {label: "7+", value: "7"},
    {label: "8+", value: "8"},
    {label: "9+", value: "9"},
    {label: "10+", value: "10"},
]
// Bedrooms

// Property Size
export const PropertySize = [
    {label: "10 m² (107 ft²)", value: 107},
    {label: "20 m² (215 ft²)", value: 215},
    {label: "30 m² (322 ft²)", value: 322},
    {label: "40 m² (430 ft²)", value: 430},
    {label: "50 m² (538 ft²)", value: 538},
    {label: "60 m² (645 ft²)", value: 645},
    {label: "70 m² (753 ft²)", value: 753},
    {label: "80 m² (861 ft²)", value: 861},
    {label: "90 m² (968 ft²)", value: 368},
    {label: "100 m² (1,076 ft²)", value: 1076},
    {label: "125 m² (1,345 ft²)", value: 1345},
    {label: "150 m² (1,614 ft²)", value: 1614},
    {label: "175 m² (1,883 ft²)", value: 1883},
    {label: "200 m² (2,152 ft²)", value: 2152},
    {label: "250 m² (2,690 ft²)", value: 2690},
    {label: "300 m² (3,229 ft²)", value: 3229},
    {label: "400 m² (4,305 ft²)", value: 4305},
    {label: "500 m² (5,381 ft²)", value: 5381},
    {label: "750 m² (8,072 ft²)", value: 8072},
    {label: "1,000 m² (10,763 ft²)", value: 10763},
    {label: "2,500 m² (26,909 ft²)", value: 26909},
    {label: "5,000 m² (53,819 ft²)", value: 53819},
    {label: "10,000 m² (107,639 ft²)", value: 107639},
]
// Property Size

// Property Size
export const PropertySizeMax = [
    {label: "10,000 m² (107,639 ft²)", value: 107639},
    {label: "5,000 m² (53,819 ft²)", value: 53819},
    {label: "2,500 m² (26,909 ft²)", value: 26909},
    {label: "1,000 m² (10,763 ft²)", value: 10763},
    {label: "750 m² (8,072 ft²)", value: 8072},
    {label: "500 m² (5,381 ft²)", value: 5381},
    {label: "400 m² (4,305 ft²)", value: 4305},
    {label: "300 m² (3,229 ft²)", value: 3229},
    {label: "250 m² (2,690 ft²)", value: 2690},
    {label: "200 m² (2,152 ft²)", value: 2152},
    {label: "175 m² (1,883 ft²)", value: 1883},
    {label: "150 m² (1,614 ft²)", value: 1614},
    {label: "125 m² (1,345 ft²)", value: 1345},
    {label: "100 m² (1,076 ft²)", value: 1076},
    {label: "90 m² (968 ft²)", value: 968},
    {label: "80 m² (861 ft²)", value: 861},
    {label: "70 m² (753 ft²)", value: 753},
    {label: "60 m² (645 ft²)", value: 645},
    {label: "50 m² (538 ft²)", value: 538},
    {label: "40 m² (430 ft²)", value: 430},
    {label: "30 m² (322 ft²)", value: 322},
    {label: "20 m² (215 ft²)", value: 215},
    {label: "10 m² (107 ft²)", value: 107},
]
// Property Size

// Capitalize
export const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s?.charAt(0).toUpperCase() + s?.slice(1)
}
// Capitalize

// Propular search Area List
export const PopularSearch_StaticAreas = {
    "area_list_1":["Ireland", "Co. Carlow", "Co. Cavan", "Co. Clare", "Co. Cork"],
    "area_list_2":["Co. Galway", "Co. Kildare", "Co. Kilkenny", "Co. Kerry", "Co. Louth"],
    "area_list_3":["Co. Limerick", "Co. Leitrim", "Co. Laois", "Co. Meath", "Co. Mayo"],
    "area_list_4":["Co. Offaly", "Co. Roscommon", "Co. Sligo", "Co. Tipperary", "Co. Waterford"],
    "area_list_5":["Co. Louth", "Co. Longford", "Co. Monaghan", "Co. Westmeath", "Co. Donegal"],
    "area_list_6":["Co. Wexford", "Co. Wicklow", "Dublin", "Co. Donegal", "Co. Sligo"],

    "comm_area_list_1":["Ireland", "Co. Carlow", "Co. Cavan", "Co. Clare", "Co. Cork", "Co. Donegal"],
    "comm_area_list_2":["Co. Limerick", "Co. Leitrim", "Co. Laois", "Co. Meath", "Co. Mayo", "Co. Monaghan"],
    "comm_area_list_3":["Co. Galway", "Co. Kildare", "Co. Kilkenny", "Co. Kerry", "Co. Longford", "Co. Louth"],
    "comm_area_list_4":["Co. Wexford", "Co. Wicklow", "Dublin", "Co. Donegal", "Co. Sligo", "Ireland"],
    "comm_area_list_5":["Co. Offaly", "Co. Roscommon", "Co. Sligo", "Co. Tipperary", "Co. Waterford", "Co. Westmeath"],
}


//  Propular search Property type
export const PopularSearch_PropertyTypes = {
    "property_types":["apartment", "house", "bungalow", "townhouse", "farm"],
    "commercial_property_types":["office", "industrial_units", "retail_units", "pubs_restaurants", "hotels", "development_land"],
    "newhomes_property_types":["bungalow", "apartment", "house", "townhouse", "duplex", "detached_house"],
}