import React, { useState, useEffect, PropTypes } from "react"
import Autosuggest from 'react-autosuggest';
import GlobalContext from '../../../Components/context/GlobalContext';
import _ from "lodash"
import axios from 'axios';
import { Link } from "gatsby";
// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => `${suggestion.name}`;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <div>
    {suggestion.name}
  </div>
);

class SearchResultBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      suggestions: [],
      areas: [],
      aliasVal: "",
      isChages: false
    };
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      aliasVal: newValue,
      isChages: true
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  suggestionSelected = (e, { suggestion }) => {

    if (suggestion) {
      this.setState({
        value: `${suggestion.name}`,
        aliasVal: suggestion.slug,
      });
    }
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  // getitems = async url => {
  //   try {
  //     const { data } = await axios.get(url, {})
  //     this.setState({ areas: data })
  //   } catch (error) {
  //   //   console.error(error)
  //   }
  // }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : this.state.areas.filter(area =>
      area.slug.toLowerCase().slice(0, inputLength) === inputValue
    ).slice(0,10);
  };


  componentDidMount() {
    // var url = process.env.GATSBY_STRAPI_SRC + "/areas.json" // process.env.GATSBY_STRAPI_SRC + "/areas"; // TODO: base URL supposed to be from .env   

    // if (this.props.department === "commercial") {
    //     url = process.env.GATSBY_STRAPI_SRC + "/areas.json" // process.env.GATSBY_STRAPI_SRC + "/areas"; // TODO: base URL supposed to be from .env   
    // }

    // this.getitems(url)
    // this.setState({value: this.props.areaVal})
    if (!this.state.areas?.length && this.props.areaList?.length) {
      this.setState({ areas: this.props.areaList })
    }
  }

  render() {
    const { value, suggestions, isChages, aliasVal } = this.state;

    // Autosuggest will pass through all these props to the input.
    var setVal = value;
    var setAliasVal = aliasVal;
    if (_.isEmpty(setVal) && !isChages && !_.isEmpty(this.props.areaVal)) {
      setVal = this.props.areaVal;
      setAliasVal = this.props.areaVal
    }

    const inputProps = {
      placeholder: this.props.placeHolder,
      value: setVal,
      onChange: this.onChange,
      className: 'form-control'
    };

    // Finally, render it!
    return (
      <div className="form-group autosuggest_container">
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          onSuggestionSelected={this.suggestionSelected}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          // alwaysRenderSuggestions={true}
        />
        <input type="hidden" className="searchVal" value={setAliasVal} />
      </div>
    );
  }
}

// export default SearchResultBox;

const SearchResultBoxWrap  = (props) => {
  return(
      <GlobalContext.Consumer>
      {globalProps => {
          return (
              <SearchResultBox {...props} {...globalProps} />
          )
      }}
      </GlobalContext.Consumer>
  )
}

export default SearchResultBoxWrap;