export const URLS = {
  NEWS: {
    name: "News",
    alias: "/about-dng-estate-agents/latest-property-news-and-insights",
  },
  Community: {
    name: "Community",
    alias: "/about-dng-estate-agents/dng-working-with-the-community",
  },
  Careers: {
    name: "Careers",
    alias: "/about-dng-estate-agents/careers",
  },
  Research: {
    name: "Research",
    alias: "/about-dng-estate-agents/research",
  },

  ForSale: {
    name: "Sale",
    alias: "/property/for-sale/in-ireland/",
  },
  ToRent: {
    name: "Rent",
    alias: "/property/to-rent/in-ireland/",
  },
  AuctionSale: {
    name: "Research",
    alias: "/auction-property/for-sale/in-ireland/",
  },
  NewHomeSale: {
    name: "Research",
    alias: "/new-homes-for-sale/in-ireland/",
  },

  Commercial_Sale: {
    name: "Research",
    alias: "/commercial-property/for-sale/in-ireland/",
  },

  Commercial_Rent: {
    name: "Research",
    alias: "/commercial-property/to-rent/in-ireland/",
  },

  Team: {
    name: "Team",
    alias: "/contact-dng-estate-agents/find-a-dng-team-member",
  },

  Office: {
    name: "Office",
    alias: "/contact-dng-estate-agents/branch-finder",
  },

  Expresses: {
    name: "DNG Express",
    alias: "/services/sellers/dng-express",
  },

  ABOUT: {
    name: "About",
    alias: "/about-dng-estate-agents",
  },
  Reviews: {
    name: "Reviews",
    alias: "/about-dng-estate-agents/reviews",
  },
  Valuation: {
    name: "Valuation",
    alias: "/book-a-valuation",
  },

  Contact: {
    name: "Contact",
    alias: "/contact-dng-estate-agents",
  },
  Upcoming_Auctions: {
    name: "Upcoming Auctions",
    alias: "/services/property-auctions/upcoming-auctions",
  },
  Past_Auctions: {
    name: "Past Auctions",
    alias: "/services/property-auctions/past-auctions/",
  },
}

export const NEWS_PAGE_URL = URLS.NEWS
export const ABOUT_PAGE_URL = URLS.ABOUT
export const Reviews_Page_Url = URLS.Reviews
export const Community_Page_Url = URLS.Community
export const Expresses_Page_Url = URLS.Expresses
export const Research_Page_Url = URLS.Research
export const Team_Page_Url = URLS.Team
export const Office_Page_Url = URLS.Office
export const ForSale_Page_Url = URLS.ForSale
export const ToRent_Page_Url = URLS.ToRent
export const Commercial_Sale_Page_Url = URLS.Commercial_Sale
export const Commercial_Rent_Page_Url = URLS.Commercial_Rent
export const NewHomeSale_Page_Url = URLS.NewHomeSale
export const AuctionSale_Page_Url = URLS.AuctionSale

export const Careers_Page_Url = URLS.Careers
export const Valuation_Page_Url = URLS.Valuation
export const Contact_Page_Url = URLS.Contact
export const Past_Auctions_Url = URLS.Past_Auctions
