import React from "react";
import { Link } from "gatsby";
import GetURL from '@Components/common/site/get-url';
const Menudata = (props) => {
  return (
    <li
      className={
        props.item.Sub_Menus && props.mainindex === props.index
          ? "active card-header card-header-parent"
          : "card-header card-header-parent"
      }

    >
      {props.item.Sub_Menus?.length > 0 ?
            <a onClick={() => { props.setmainindex(props.mainindex === props.index ? null : props.index); props.setsubindex(null); }} className="d-block menu-link">
        <GetURL
          // i={i} 

          label={props.item.Parent_Label} alias={props.item.Parent_Alias?.Alias}
        />
        {/* <Link to={props.item.Parent_Alias?.Alias} className="d-block menu-link">
        {props.item.Parent_Label}
      </Link> */}
        {props.item.Sub_Menus?.length > 0 && props.mainindex === props.index ? (
          <i className="icon-minus"></i>
        ) : (
          props.item.Sub_Menus?.length > 0 && <i className="icon-plus"></i>
        )}
      </a>
      :
      <a className="d-block menu-link">
      <GetURL
        // i={i} 

        label={props.item.Parent_Label} alias={props.item.Parent_Alias?.Alias}
      />
      
    </a>}

      {props.item.Sub_Menus && props.mainindex === props.index && (
        <ul className="submenu-list">
          {props.item.Sub_Menus.map((subitem, i) => (
            <li
              className={
                subitem.Add_Sub_menu && props.subindex === i
                  ? "active card-header card-header-child"
                  : "card-header card-header-child"
              }

            >
              <a onClick={(e) =>
                props.setsubindex(props.subindex === i ? null : i)
              } className="d-block sub-menu-link">
                <GetURL
                  label={subitem.Parent_Label} alias={subitem.Parent_Url?.Alias}
                />

                {subitem.Add_Sub_menu?.length > 0 && props.subindex === i ? (
                  <i className="icon-minus"></i>
                ) : (
                  subitem.Add_Sub_menu?.length > 0 && <i className="icon-plus faded"></i>
                )}
              </a>
              {subitem.Add_Sub_menu?.length > 0 && props.subindex === i && (
                <ul className="inner-submenu">
                  {subitem.Add_Sub_menu?.map((list) => (
                    <li
                      onClick={(e) => e.stopPropagation()}
                      className="menu-accordion"
                    >
                      <GetURL
                        label={list.Label} alias={list.Url?.Alias}
                      />
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default Menudata;
