import React from "react"
import Autosuggest from 'react-autosuggest';
import GlobalContext from '../../context/GlobalContext';
import _ from "lodash"
import axios from 'axios';
// import "./predictiveSearch.scss"

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.name;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => {
    return(
        <div>
            {suggestion.name}
        </div>
    )
};


class SearchBox extends React.Component {
    constructor(props) {
        super(props);
        // Autosuggest is a controlled component.
        // This means that you need to provide an input value
        // and an onChange handler that updates this value (see below).
        // Suggestions also need to be provided to the Autosuggest,
        // and they are initially empty because the Autosuggest is closed.
        this.state = {
            value: '',
            suggestions: [],
            areas: [],
            aliasVal:"",
            isChages:false
        };
    }

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue,
            aliasVal: newValue,
            isChages: true
        });
        if (this.props?.setAreaVal){
            //console.log("newValue", newValue)
            this.props.setAreaVal(newValue)
        }

    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    suggestionSelected = (e, {suggestion})=>{

        if(suggestion){
            //console.log("suggestion", suggestion)
            this.setState({
                value: '', // suggestion.name,
                aliasVal: suggestion.slug,
            });
            if ( this.props.setAreaVal )
                this.props.setAreaVal(suggestion.slug)
            this.props.handleMultiAreaFun(suggestion);

            if(this.props.setH1Areas) {
                this.props.setH1Areas([...this.props.h1_areas, suggestion.display_name])
            }
        }
    }
    // getitems = async url => {
    //     try {
    //         const { data } = await axios.get(url, {})
    //         if (this.props?.setAreasList)
    //             this.props.setAreasList(data)
    //         this.setState({ areas: data })
    //     } catch (error) {
    //         console.error(error)
    //     }
    // }

    // Teach Autosuggest how to calculate suggestions for any given input value.
    getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        const filterList1 = this.state.areas.filter(area =>  area.name.toLowerCase().slice(0, inputLength) === inputValue)
        const filterList2 = this.state.areas.filter(area =>  area.name.replace(/ *\([^)]*\) */g, "").toLowerCase().includes(inputValue.toLowerCase()))
      //  console.log('filterList', filterList1, filterList2, _.uniqBy(filterList1.concat(filterList2), 'slug'))
        const suggestionList = _.uniqBy(filterList1.concat(filterList2), 'slug');
        return inputLength === 0 ? [] :  suggestionList;
    };

   


    componentDidMount() {
        // let url = `${process.env.GATSBY_STRAPI_SRC}/${this.props?.fileName || 'areas.json'}`
        // // let url = process.env.GATSBY_STRAPI_SRC + "/areas.json"

        // this.getitems(url)
        if (!this.state.areas?.length && this.props.areaList?.length) {
            this.setState({ areas: this.props?.areaList })
          }
        if (this.props?.areaVal)
            this.setState({value: this.props.areaVal.replace(/-/g, ' ')})

    }
     capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s?.charAt(0).toUpperCase() + s?.slice(1)
      }

    render() {
        const { value, suggestions, isChages, aliasVal } = this.state;

        // const autosuggestInputProps = this.props?.autosuggestInputProps || {}

        // Autosuggest will pass through all these props to the input.
        var setVal = value;
        if(_.isEmpty(setVal) && !isChages){
            if (this.props?.areaVal)
                setVal = this.props.areaVal.replace(/-/g, ' ');
        }

        const inputProps = {
            placeholder: this.props?.placeholder || 'City, Community or Tower',
            value: this.capitalize(setVal),
            onChange: this.onChange,
            // ...autosuggestInputProps
        };

        //console.log("areaVal ===>", this?.props?.areaVal);


        // Finally, render it!       

       
        return (
            <>
                <div className="form-group autosuggest_container">
                    <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                        getSuggestionValue={getSuggestionValue}
                        onSuggestionSelected={this.suggestionSelected}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                    />
                    <input type="hidden" className="searchVal" id="searchVal" value={this.capitalize(aliasVal)} />
                </div>
            </>
        );
    }
}

// export default SearchBox;

const SearchResultBoxWrap  = (props) => {
    return(
        <GlobalContext.Consumer>
        {globalProps => {
            return (
                <SearchBox {...props} {...globalProps} />
            )
        }}
        </GlobalContext.Consumer>
    )
  }
  
  export default SearchResultBoxWrap;