import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import "./findProperty.scss"
import $ from "jquery"

import TopBar from "./TopBar"
import FindProperty from "./FindProperty"
import BrandLogo from "../../images/logo.svg"
import "./Header.scss"
import Menudata from "./Menudata"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import GetURL from "@Components/common/site/get-url"
import Modal from "react-bootstrap/Modal"
import { isSafari, isIOS, isMacOs, isWindows } from "react-device-detect"

const Header = (props) => {
  const [showi, setShowi] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const handleClosei = () => setShowi(false)
  const handleShowi = event => {
    setShowi(true)
  }
  const [scroll, setScroll] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 48)
    })

  }, [])

  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        menuHeaders(publicationState: LIVE, sort: "Sort:asc") {
          Parent_Label
          Sub_Menus {
            Add_Sub_menu {
              Label
              Url {
                Alias
              }
            }
            Parent_Label
            Parent_Url {
              Alias
            }
          }
          Publish
          Sort
          Parent_Alias {
            Alias
          }
        }
      }
    }
  `)
  const [mainindex, setmainindex] = useState(null)
  const [subindex, setsubindex] = useState(null)
  const [show, setShow] = useState(false)
  const [showFindProperty, setShowFindProperty] = useState(false)
  const [state, setState] = useState([
    {
      name: "Find a Property",
      isActive: false,
    },
    {
      name: "Sell or Let with DNG",
      submenu: [
        {
          name: "Mortgages",
          isActive: false,
        },
      ],
      isActive: false,
    },

    {
      name: "Services",
      submenu: [
        {
          name: "Buyers",
          submenu: [
            "Mortgages ",
            "Seller's guide",
            "Tenants guide",
            "Buyers guide",
          ],
          isActive: false,
        },
        {
          name: "Sellers",
          submenu: ["Free Valuation", "Why Let Through Us", "Guide to Letting"],
          isActive: false,
        },
        {
          name: "Renting",
          submenu: ["Seller's guide", "Tenants guide", "Buyers guide"],
          isActive: false,
        },
        {
          name: "Landlords",
          submenu: ["Seller's guide", "Tenants guide", "Buyers guide"],
          isActive: false,
        },
        {
          name: "New Homes",
          submenu: [
            "Why Let with DNG",
            "Property Valuation",
            "Asset Management",
            "Rental Yield Calculator",
          ],
          isActive: false,
        },
        {
          name: "Commercial",
          submenu: ["Seller's guide", "Tenants guide", "Buyers guide"],
          isActive: false,
        },
        {
          name: "Auctions",
          submenu: ["Seller's guide", "Tenants guide", "Buyers guide"],
          isActive: false,
        },
      ],
      isActive: false,
    },
    {
      name: "About",
      submenu: [
        {
          name: "Seller's guide",
          isActive: false,
        },
      ],
      isActive: false,
    },
    {
      name: "Contact",
      isActive: false,
    },
  ])

  const showMenu = () => {
    setShow(!show)
    if (typeof document !== `undefined`) {
      document.querySelector("body").classList.add("overflow-hidden")
    } else {
    }
  }
  const hideMenu = () => {
    setShow(!show)
    if (typeof document !== `undefined`) {
      document.querySelector("body").classList.remove("overflow-hidden")
    } else {
    }
    setState(
      state.map(menu => {
        if (Array.isArray(menu.submenu)) {
          let p = menu.submenu.map(sub => ({ ...sub, ...{ isActive: false } }))
          return { ...menu, ...{ submenu: p }, ...{ isActive: false } }
        } else {
          return { ...menu, ...{ isActive: false } }
        }
      })
    )
  }

  const handlerOpenMenu = index => {
    let newState = [...state]
    let a = newState.map((item, i) => {
      if (index !== i) {
        return { ...item, isActive: false }
      } else {
        return item
      }
    })
    let isActive = a[index].isActive
    a[index].isActive = !isActive
    setState(a)
  }

  const handlerOpenSubMenu = (e, index, i) => {
    e.stopPropagation()
    let newState = [...state]
    let a = newState[index].submenu.map((item, j) => {
      if (i !== j) {
        return { ...item, isActive: false }
      } else {
        return item
      }
    })
    newState[index].submenu = a
    let isActive = newState[index].submenu[i].isActive
    newState[index].submenu[i].isActive = !isActive
    setState(newState)
  }

  const mobileNaviagationClasses = show
    ? "navigation-wrap active"
    : "navigation-wrap"

  return (
    <header className="header">
      <Helmet>
        {/* <script
          type="text/javascript"
          src="https://www.bugherd.com/sidebarv2.js?apikey=3rm2yqyqhlcqst1fyv1ljq"
          async="true"
        ></script> */}
        <script src="https://cdn.adfenix.com/tracker.js" async></script>
      </Helmet>
      <Modal
        show={showi}
        centered={true}
        onHide={handleClosei}
        backdrop="static"
        keyboard={false}
        animationIn="slideInLeft"
        animationOut="slideOutRight"
        dialogClassName="search-model"
      >
        <Modal.Header closeButton className="contact-close-btn"></Modal.Header>
        <Modal.Body className="form">
          <div className="backdrop">
            <div className="find-property-wrapper">
              <FindProperty setFreeTextSearch={props.setFreeTextSearch} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <TopBar />
      <div className={scroll ? "header-bottom fixed" : "header-bottom"}>
        <Container>
          <Row>
            <Col sm="12">
              <div className="header-wrapper">
                <div className="logo">
                  <Link to="/">
                    <img src={BrandLogo} alt="logo" />
                  </Link>
                </div>
                <div className="nav-right d-flex align-items-center d-lg-block">
                  <div className="header-btn d-md-none">
                    <button
                      onClick={() => handleShowi()}
                      type="button"
                      className="search-btn"
                    >
                      <i className="icon-search"></i>
                    </button>
                    {/* <button type="button" className="user-btn">
                      <i className="icon-user"></i>
                    </button> */}
                  </div>
                  <nav className="nav d-none d-lg-block">
                    <ul className="d-flex mb-0 main-ul-menu">
                      {data.glstrapi.menuHeaders.map((node, i) =>
                          i === 0 ? (
                            <li
                              className="current-link"
                              onMouseEnter={() => setShowSearch(true)}
                              onMouseLeave={() => setShowSearch(false)}
                            >
                              <a href="javascript:void(0)">Find a Property</a>

                              <div
                                className={`backdrop ${
                                  showSearch ? "d-block" : "d-none"
                                }`}
                              >
                                <div className="find-property-wrapper">
                                  <FindProperty onClose={setShowFindProperty} setFreeTextSearch={props.setFreeTextSearch} />
                                </div>
                              </div>
                            </li>
                          ) : (
                            <li
                              className={`main-li-menu ${node.Parent_Alias?.Alias}`}
                            >
                              <GetURL
                                // i={i} class={active === i ? "active" : null}
                                label={node.Parent_Label}
                                alias={node.Parent_Alias?.Alias}
                              /> 
                              {node.Sub_Menus?.length > 0 &&
                                node.Parent_Label === "Services" && (
                                  <ul
                                    className={
                                      node.Parent_Label === "Services"
                                        ? "menubar-submenu services"
                                        : "menubar-submenu"
                                    }
                                  >
                                    <Row className="submenu-row">
                                      {node.Sub_Menus.map((subitem, i) => (
                                        <Col md={3}>
                                          <li className="sub-parent-services">
                                            <GetURL
                                              label={subitem.Parent_Label}
                                              alias={subitem.Parent_Url?.Alias}
                                            />
                                            {subitem.Add_Sub_menu?.length >
                                              0 && (
                                              <ul>
                                                {subitem.Add_Sub_menu.map(
                                                  (list, i) => (
                                                    <li>
                                                      <GetURL
                                                        label={list.Label}
                                                        alias={list.Url?.Alias}
                                                      />
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            )}
                                          </li>
                                        </Col>
                                      ))}
                                    </Row>
                                  </ul>
                                )}
                              {node.Sub_Menus?.length > 0 &&
                                node.Parent_Label !== "Services" && (
                                  <ul className="menubar-submenu small">
                                    <Row className="submenu-row">
                                      <Col md={12}>
                                        {node.Sub_Menus.map((subitem, i) => (
                                          <li>
                                            <GetURL
                                              label={subitem.Parent_Label}
                                              alias={subitem.Parent_Url?.Alias}
                                            />
                                            {subitem.Add_Sub_menu?.length >
                                              0 && (
                                              <ul>
                                                {subitem.Add_Sub_menu.map(
                                                  (list, i) => (
                                                    <li>
                                                      <GetURL
                                                        label={list.Label}
                                                        alias={list.Url?.Alias}
                                                      />
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            )}
                                            {/* {subitem.Add_Sub_menu?.length > 0 &&
                                    <ul>
                                      {subitem.Add_Sub_menu.map((list, i) => (
                                        <li
                                        >
                                          <GetURL
                                            label={list.Label} alias={list.Url?.Alias}
                                          />
                                        </li>
                                      ))}
                                    </ul>
                                  } */}
                                          </li>
                                        ))}
                                      </Col>
                                    </Row>
                                  </ul>
                                )}
                            </li>
                          )
                        // <li>
                        // 	<Link to="/" className="search-dd">Sell or Let with DNG</Link>
                        // 	<Link to="/" className="search-tab">Why DNG</Link>
                        // </li>
                        // <li className="d-none d-lg-block">
                        // 	<Link to="/">Services</Link>
                        // </li>
                        // <li className="d-none d-lg-block">
                        // 	<Link to="/">About</Link>
                        // </li>
                        // <li>
                        // 	<Link to="/">Contact</Link>
                        // </li>
                      )}
                    </ul>
                  </nav>
                  <div className="menu-wrap d-lg-none">
                    <button className="hamburger" onClick={showMenu}>
                      <span className="line"></span>
                      <span className="line"></span>
                      <span className="line"></span>
                    </button>
                  </div>
                </div>
                <div className={mobileNaviagationClasses}>
                  <div className="btn-wrapper d-flex justify-content-end">
                    <a
                      href="javascript:;"
                      onClick={() => handleShowi()}
                      aria-current="page"
                      className="search-btn"
                    >
                      <i className="icon-search"></i>
                    </a>
                    {/* <Link to="/" aria-current="page" className="user-btn">
                      <i className="icon-user"></i>
                    </Link> */}
                    <button
                      type="button"
                      className="close-btn"
                      onClick={hideMenu}
                    >
                      <i className="icon-close"></i>
                    </button>
                  </div>
                  <div className="navigation">
                    <ul className="menu-accordion">
                      {data.glstrapi.menuHeaders?.map((item, i) => (
                        <Menudata
                          mainindex={mainindex}
                          setmainindex={setmainindex}
                          subindex={subindex}
                          setsubindex={setsubindex}
                          key={i}
                          index={i}
                          handlerOpenMenu={handlerOpenMenu}
                          handlerOpenSubMenu={handlerOpenSubMenu}
                          item={item}
                        />
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* {showFindProperty && (
        <div className="backdrop">
          <FindProperty onClose={setShowFindProperty} />
        </div>
      )} */}
    </header>
  )
}

export default Header
