import React, { useState } from "react"
import { Link } from "gatsby"
import { Container } from "react-bootstrap"

import "./TopBar.scss"
import { Valuation_Page_Url } from "@Components/common/site/constants"
import Modal from "react-bootstrap/Modal"
import FindProperty from "./FindProperty"

const myaccountURL = process.env.GATSBY_MYACCOUNT_URL || ""

const TopBar = (props) => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = event => {
    setShow(true)
  }
 

  return (
    <div className="top-bar d-none d-md-block">
      <Modal
        show={show}
        centered={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        animationIn="slideInLeft"
        animationOut="slideOutRight"
        dialogClassName="search-model"
      >
        <Modal.Header closeButton className="contact-close-btn"></Modal.Header>
        <Modal.Body className="form">
          <div className="backdrop">
            <div className="find-property-wrapper header-block-search">
              <FindProperty />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Container>
        <ul className="d-flex justify-content-end">
          <li className="top-bar-search">
            <a
              href="javascript:;"
              onClick={() => handleShow()}
              className="d-block"
            >
              <i className="icon-search"></i>
              <span className="text d-inline-block">Search</span>
            </a>
          </li>
          <li>
            <Link to={Valuation_Page_Url.alias + "/"} className="d-block">
              <i className="icon-house"></i>
              <span className="text d-inline-block">Request a Valuation</span>
            </Link>
          </li>
          <li>
            <Link
              to="/contact-dng-estate-agents/branch-finder/"
              className="d-block"
            >
              <i className="icon-buildings"></i>
              <span className="text d-inline-block">Branch Finder</span>
            </Link>
          </li>
          <li>
            <Link to={myaccountURL} className="sb-myacc">
              <i className="icon-user"></i>
              <span className="text d-inline-block">My Account</span>
            </Link>
          </li>
        </ul>
      </Container>
    </div>
  )
}

export default TopBar
