import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import {
  Commercial_Sale_Page_Url,
  Commercial_Rent_Page_Url,
  NewHomeSale_Page_Url,
  ToRent_Page_Url,
  ForSale_Page_Url,
  AuctionSale_Page_Url,
  URLS,
} from "@Components/common/site/constants"
/**
 * Assets
 */

const GetURL = props => {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        allMenus(publicationState: LIVE) {
          Alias
          URL
          Label
          Main_Parent {
            Alias
            URL
          }
          Sub_Parent {
            Alias
            URL
          }
          Link_Type
          External_Url
        }
      }
    }
  `)

  const PageURL = data.glstrapi?.allMenus
    .filter(item => item?.Alias === props?.alias)
    .pop()


  var URL = PageURL?.URL

  if (PageURL?.Main_Parent != null && PageURL?.Sub_Parent == null) {
    URL = PageURL?.Main_Parent?.URL + "/" + URL
  } else if (PageURL?.Main_Parent != null && PageURL?.Sub_Parent != null) {
    URL = PageURL?.Main_Parent?.URL + "/" + PageURL?.Sub_Parent?.URL + "/" + URL
  }

  // useEffect(() => {
  //   if (typeof window !== undefined) {
  //     if (window.scrollY) {
  //       window.scroll(0, 0)
  //     }
  //   }
  // }, [])

  return (
    <>
      {PageURL?.Link_Type === "Internal" && (
        <>
          {URL === "property/for-sale" ? (
            <Link to={ForSale_Page_Url.alias} className={props.class}>
              {props.label}
            </Link>
          ) : URL === "property/to-rent" ? (
            <Link to={ToRent_Page_Url.alias} className={props.class}>
              {props.label}
            </Link>
          ) : URL === "new-homes-for-sale" ? (
            <Link to={NewHomeSale_Page_Url.alias} className={props.class}>
              {props.label}
            </Link>
          ) : URL === "commercial-property-sale" ? (
            <Link to={Commercial_Sale_Page_Url.alias} className={props.class}>
              {props.label}
            </Link>
          ) : URL === "commercial-property-rent" ? (
            <Link to={Commercial_Rent_Page_Url.alias} className={props.class}>
              {props.label}
            </Link>
          ) : URL === "auction-property-sale" ? (
            <Link to={AuctionSale_Page_Url.alias} className={props.class}>
              {props.label}
            </Link>
          ) : URL === "services/buyers/investment-opportunities" ? (
            <a href="/property/for-sale/in-ireland/with-investment/">
              {props.label}
            </a>
          ) :URL==="services/commercial/commercial-props"&&props.class?(
            <Link to="/commercial-property/for-sale/in-ireland/" className={props.class}>{props.label}</Link>
          ):URL==="services/commercial/commercial-props"?(
            <Link to="/commercial-property/for-sale/in-ireland/">{props.label}</Link>
          )          
          :
          
          (
            //     props.i === 0 ? <Link className={props.class}>
            //     {props.label}

            // </Link> :
            <Link
              to={`/${URL}/`}
              className={props.class}
              state={props.stateData}
            >
              {props.label}
            </Link>
          )}
        </>
      )}
      {props.alias === "upcoming-auctions" && (
        <Link to={`${URLS.Upcoming_Auctions.alias}`} className={props.class}>
          {props.label}
        </Link>
      )}
      {props.Link_Type === "External_url" && (
        <>
          <a href={props.alias} target="_blank" className={props.class}>
            {props.label}
          </a>
        </>
      )}
       {PageURL?.Link_Type === "External" && props.alias !== "upcoming-auctions"&& (
        <>
          <a href={PageURL?.External_Url} target="_blank" className={props.class}>
            {props.label}
          </a>
        </>
      )}
      {PageURL?.Link_Type === "External_New_Window" && (
        <>
          <a
            href={PageURL?.External_URL}
            target="_blank"
            className={props.class}
          >
            {props.label}
          </a>
        </>
      )}
    </>
  )
}

export default GetURL

// Specifies the default values for props:
GetURL.defaultProps = {
  label: "",
  class: "",
  arrow: "no",
  dropdown: "no",
  style: "",
  URL: "",
  icon: "",
}
