import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

const AreaList = ({areas, removeArea, className=''}) => {
    if (!areas || areas.length < 1)
        return <></>

    function removeAreaFun(e, area, area_disp_name) {
        e.preventDefault();
        removeArea(area, area_disp_name, false)
    }
    function removeAreaAllFun(e, area, area_disp_name) {
        e.preventDefault();
        removeArea('', '', true)
    }

    console.log("areas ==>", areas);


    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <div className={`area-list ${className}`}>
                            <div className="section-tags-wrapper pt-1">
                                <ul className="list-tags">
                                    {areas.map((item, key) => {
                                        return(
                                            <>
                                                <li>
                                                    <div className="tag">
                                                        <div className="tag-text"><span>{item.name}</span></div>
                                                        <Button variant="none" className="tag-clear link-tag-clear pointer-events-all cursor-pointer" onClick={(e) => removeAreaFun(e, item.slug, item.display_name)}>
                                                            <i className="icon-search-close"></i>
                                                        </Button>
                                                    </div>
                                                </li>
                                            </>
                                        )
                                    })}
                                    <li className="pointer-events-all pointer-events-close-all clear-btn-area">
                                        <div className="tag">
                                            <div className="tag-text"><span> </span></div>
                                            <Button variant="none" className="tag-clear link-clear-all m-0" onClick={(e) => removeAreaAllFun(e, '', '')}>
                                                <i className="icon-search-close"></i>
                                            </Button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default AreaList;