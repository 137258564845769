import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import Select from "react-dropdown-select";
import { navigate } from "@reach/router"
import $ from "jquery";
import {
	resiSalesPropertyType,
	resiLettingsPropertyType,
	newHomesSalesPropertyType,
	commercialSalesPropertyType
} from "../common/propertyUtils";
import SearchResultInputBox from '../SearchResults/SecondaryHeader/SearchResultBox';
import SearchMultipleArea from '../SearchResults/SecondaryHeader/search-multiarea';
import AreaListBox from '../SearchResults/SecondaryHeader/AreaList';
import _, { isEmpty, filter, find, isArray, isString, get, indexOf } from "lodash"
import './findProperty.scss';
import PropertySelect from "./PropertySelect"

const FindProperty = (props) => {
	const locationRef = useRef();

	// Multi area search
	const [areaVal, setAreaVal] = useState('');
	const [areasList, setAreasList] = useState([]);
	const [areasArr, setAreasArr] = useState([]);
	const [reload, setReload] = useState(false);

	const handleMultiAreaFun = (val) => {
		let areas = [];
		if (_.isEmpty(find(areasArr, val))) {
			areas = [...areasArr]
			areas.push(val)
			setAreasArr(areas)
		}
	}

	const removeArea = (area, clear = false) => {
		if (!clear) {
			let prevAreas = areasArr.filter(x => x.slug !== area)
			setAreasArr(prevAreas);
		} else {
			setAreasArr([]);
		}
	}

	const submitHandler = (event) => {
		event.preventDefault();
	}

	const [toggle, setColour] = useState(false);
	const changeColour = () => {
		setColour(!toggle);
	}
	const [tab, settab] = useState('residential')
	const tabChange = (key) => {
		settab(key)
	}


	useEffect(() => {
		var elements = document.getElementById('popvalue').getElementsByTagName('a');

		for(let element in elements){
			if(elements[element].href){
			elements[element].href=elements[element].href?.replace("#","")
			}
		}

		setAreasArr([])
		setAreaVal('')
		setAreasList([]) 
		let a1 = window?.location.pathname.indexOf("/property/for-sale") > -1
		let a2 = window?.location.pathname.indexOf("/property/to-rent") > -1
		let a3 = window?.location.pathname.indexOf("/commercial-property/for-sale") > -1
		let a4 = window?.location.pathname.indexOf("/commercial-property/to-rent") > -1
		let a5 = window?.location.pathname.indexOf("/new-homes-for-sale") > -1
		let a6 = window?.location.pathname.indexOf("/auction-property/for-sale") > -1
		if (a1 || a2 || a3 || a4 || a5 || a6) {
			setReload(true);
		}
	}, [tab])

	// 
	const [areaValResi, setAreaValResi] = useState('');
	const [propertyvalResi, setPropertyValResi] = useState('');

	const [areaValNew, setAreaValNew] = useState('');
	const [propertyvalNew, setPropertyValNew] = useState('');

	const [areaValComm, setAreaValComm] = useState('');
	const [propertyvalComm, setPropertyValComm] = useState('');

	const [areaValAuc, setAreaValAuc] = useState('');
	const [propertyvalAuc, setPropertyValAuc] = useState('');

	const submitSearchResiSale = (e) => {

		if (areaVal) {
			props?.setFreeTextSearch && props?.setFreeTextSearch(areaVal);
		} else {
			props?.setFreeTextSearch && props?.setFreeTextSearch([]);
		}

		var areaValue = (areaVal.replace(/ /g, "-").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').toLowerCase());

		if (areasArr?.length > 0) {
			areaValue = areasArr.map((items) => items.slug.toLowerCase()).join("-and-")
			props?.setFreeTextSearch && props?.setFreeTextSearch([]);
		}
		// var areaValue = $(".location-search-residential input").val().split(', ').join('-').replace("(", "").replace(")", "").replace(/ /g, "-").replace(/---/g, "-").replace(/'/g, "").toLowerCase();

		var buildingValue = propertyvalResi ? propertyvalResi : $(".property input[type='hidden']").val();

		var searchFilterResults = "";

		if (areaValue) {
			searchFilterResults = "/property/for-sale/in-" + areaValue + "/";
		} else {
			searchFilterResults = "/property/for-sale/in-ireland/";
		}

		if (buildingValue) {
			searchFilterResults += "type-" + buildingValue + "/";
		}

		
		if(reload){
			window.location.href = searchFilterResults;
		}else{
			navigate(searchFilterResults);
		}

		window.scrollTo(0, 0);
	}

	const submitSearchResiRent = (e) => {
		// var areaValue = $(".location-search-residential input").val().split(', ').join('-').replace("(", "").replace(")", "").replace(/ /g, "-").replace(/---/g, "-").replace(/'/g, "").toLowerCase();
		if (areaVal) {
			props?.setFreeTextSearch && props?.setFreeTextSearch(areaVal);
		} else {
			props?.setFreeTextSearch && props?.setFreeTextSearch([]);
		}

		var areaValue = (areaVal.replace(/ /g, "-").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').toLowerCase());

		if (areasArr?.length > 0) {
			areaValue = areasArr.map((items) => items.slug.toLowerCase()).join("-and-")
			props?.setFreeTextSearch && props?.setFreeTextSearch([]);
		}

		var buildingValue = propertyvalResi ? propertyvalResi : $(".property input[type='hidden']").val();

		var searchFilterResults = "";

		if (areaValue) {
			searchFilterResults = "/property/to-rent/in-" + areaValue + "/";
		} else {
			searchFilterResults = "/property/to-rent/in-ireland/";
		}

		if (buildingValue) {
			searchFilterResults += "type-" + buildingValue + "/";
		}

		if(reload){
			window.location.href = searchFilterResults;
		}else{
			navigate(searchFilterResults);
		}

		window.scrollTo(0, 0);
	}

	const submitSearchNewSale = (e) => {
		// var areaValue = $(".location-search-newhomes input").val().split(', ').join('-').replace("(", "").replace(")", "").replace(/ /g, "-").replace(/---/g, "-").replace(/'/g, "").toLowerCase();
		if (areaVal) {
			props?.setFreeTextSearch && props?.setFreeTextSearch(areaVal);
		} else {
			props?.setFreeTextSearch && props?.setFreeTextSearch([]);
		}

		var areaValue = (areaVal.replace(/ /g, "-").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').toLowerCase());

		if (areasArr?.length > 0) {
			areaValue = areasArr.map((items) => items.slug.toLowerCase()).join("-and-")
			props?.setFreeTextSearch && props?.setFreeTextSearch([]);
		}

		var buildingValue = propertyvalNew ? propertyvalNew : $(".property input[type='hidden']").val();

		var searchFilterResults = "";

		if (areaValue) {
			searchFilterResults = "/new-homes-for-sale/in-" + areaValue + "/";
		} else {
			searchFilterResults = "/new-homes-for-sale/in-ireland/";
		}

		if (buildingValue) {
			searchFilterResults += "type-" + buildingValue + "/";
		}

		if(reload){
			window.location.href = searchFilterResults;
		}else{
			navigate(searchFilterResults);
		}

		window.scrollTo(0, 0);
	}

	const submitSearchCommSale = (e) => {
		// var areaValue = $(".location-search-commercial input").val().split(', ').join('-').replace("(", "").replace(")", "").replace(/ /g, "-").replace(/---/g, "-").replace(/'/g, "").toLowerCase();
		if (areaVal) {
			props?.setFreeTextSearch && props?.setFreeTextSearch(areaVal);
		} else {
			props?.setFreeTextSearch && props?.setFreeTextSearch([]);
		}

		var areaValue = (areaVal.replace(/ /g, "-").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').toLowerCase());

		if (areasArr?.length > 0) {
			areaValue = areasArr.map((items) => items.slug.toLowerCase()).join("-and-")
			props?.setFreeTextSearch && props?.setFreeTextSearch([]);
		}

		var buildingValue = propertyvalComm ? propertyvalComm : $(".property input[type='hidden']").val();

		var searchFilterResults = "";

		if (areaValue) {
			searchFilterResults = "/commercial-property/for-sale/in-" + areaValue + "/";
		} else {
			searchFilterResults = "/commercial-property/for-sale/in-ireland/";
		}

		if (buildingValue) {
			searchFilterResults += "type-" + buildingValue + "/";
		}

		if(reload){
			window.location.href = searchFilterResults;
		}else{
			navigate(searchFilterResults);
		}

		window.scrollTo(0, 0);
	}

	const submitSearchCommRent = (e) => {
		// var areaValue = $(".location-search-commercial input").val().split(', ').join('-').replace("(", "").replace(")", "").replace(/ /g, "-").replace(/---/g, "-").replace(/'/g, "").toLowerCase();
		if (areaVal) {
			props?.setFreeTextSearch && props?.setFreeTextSearch(areaVal);
		} else {
			props?.setFreeTextSearch && props?.setFreeTextSearch([]);
		}

		var areaValue = (areaVal.replace(/ /g, "-").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').toLowerCase());

		if (areasArr?.length > 0) {
			areaValue = areasArr.map((items) => items.slug.toLowerCase()).join("-and-")
			props?.setFreeTextSearch && props?.setFreeTextSearch([]);
		}

		var buildingValue = propertyvalComm ? propertyvalComm : $(".property input[type='hidden']").val();

		var searchFilterResults = "";

		if (areaValue) {
			searchFilterResults = "/commercial-property/to-rent/in-" + areaValue + "/";
		} else {
			searchFilterResults = "/commercial-property/to-rent/in-ireland/";
		}

		if (buildingValue) {
			searchFilterResults += "type-" + buildingValue + "/";
		}

		if(reload){
			window.location.href = searchFilterResults;
		}else{
			navigate(searchFilterResults);
		}

		window.scrollTo(0, 0);
	}

	const submitSearchAucSale = (e) => {
		// var areaValue = $(".location-search-auction input").val().split(', ').join('-').replace("(", "").replace(")", "").replace(/ /g, "-").replace(/---/g, "-").replace(/'/g, "").toLowerCase();
		if (areaVal) {
			props?.setFreeTextSearch && props?.setFreeTextSearch(areaVal);
		} else {
			props?.setFreeTextSearch && props?.setFreeTextSearch([]);
		}

		var areaValue = (areaVal.replace(/ /g, "-").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').toLowerCase());

		if (areasArr?.length > 0) {
			areaValue = areasArr.map((items) => items.slug.toLowerCase()).join("-and-")
			props?.setFreeTextSearch && props?.setFreeTextSearch([]);
		}

		var buildingValue = propertyvalAuc ? propertyvalAuc : $(".property input[type='hidden']").val();

		var searchFilterResults = "";

		if (areaValue) {
			searchFilterResults = "/auction-property/for-sale/in-" + areaValue + "/";
		} else {
			searchFilterResults = "/auction-property/for-sale/in-ireland/";
		}

		if (buildingValue) {
			searchFilterResults += "type-" + buildingValue + "/";
		}

		if(reload){
			window.location.href = searchFilterResults;
		}else{
			navigate(searchFilterResults);
		}

		window.scrollTo(0, 0);
	}
	// 

	// const [areaValNewHomes, setAreaValNewHomes] = useState('');
	// const [areasListNewHomes, setAreasListNewHomes] = useState([]);
	// const [areasArrNewHomes, setAreasArrNewHomes] = useState([]);

	// const handleMultiAreaFunNewHomes = (val) => {
	//     let areas = [];
	//     if (_.isEmpty(find(areasArrNewHomes, val))) {
	//         areas = [...areasArrNewHomes]
	//         areas.push(val)
	//         setAreasArrNewHomes(areas)
	//     }
	// }

	// const removeAreaNewHomes = (area, clear=false) => {
	//     if (!clear) {
	//         let prevAreas = areasArrNewHomes.filter( x => x.slug !== area)
	//         setAreasArrNewHomes(prevAreas);
	//     } else {
	//         setAreasArrNewHomes([]);
	//     }
	// }


	// const [areaValCommercial, setAreaValCommercial] = useState('');
	// const [areasListCommercial, setAreasListCommercial] = useState([]);
	// const [areasArrCommercial, setAreasArrCommercial] = useState([]);

	// const handleMultiAreaFunCommercial = (val) => {
	//     let areas = [];
	//     if (_.isEmpty(find(areasArrCommercial, val))) {
	//         areas = [...areasArrCommercial]
	//         areas.push(val)
	//         setAreasArrCommercial(areas)
	//     }
	// }

	// const removeAreaCommercial = (area, clear=false) => {
	//     if (!clear) {
	//         let prevAreas = areasArrCommercial.filter( x => x.slug !== area)
	//         setAreasArrCommercial(prevAreas);
	//     } else {
	//         setAreasArrCommercial([]);
	//     }
	// }
	// Multi area search

	return (
		<div className="find-property" id="popvalue">
			<div className="close-btn d-md-none">
				<button onClick={() => props.onClose()}>
					<i className="icon-close"></i>
				</button>
			</div>
			<Tabs onSelect={(e) => tabChange(e)} defaultActiveKey="residential" id="uncontrolled-tab-example" className="mb-3 search-nav-tab">
			<Tab eventKey="residential" title="Residential">
					<div className="form-wrap">
						<form onSubmit={submitHandler}>
							<div className="form-content">
								<div className="input-wrapper find-property-location-search location-search-residential">
									{/* <SearchResultInputBox areaVal={areaValResi} placeHolder={`Country, Region or Area`} department={"residential"} /> */}
									<SearchMultipleArea
										areaVal={areaVal}
										placeholder={"Location or Address"}
										setAreaVal={setAreaVal}
										setAreasList={setAreasList}
										handleMultiAreaFun={handleMultiAreaFun}
										autosuggestInputProps={{ className: 'react-autosuggest__input refine-autosuggest-input' }}
									/>
								</div>
								<div className="select-boxes find-property-dropdown">

									<PropertySelect
									propertyvalResi={propertyvalResi}
									resiSalesPropertyType={resiSalesPropertyType}
									setPropertyValResi={setPropertyValResi}	
									/>
									{/* <Select
										placeholder={propertyvalResi ? propertyvalResi : "Property Type 1"}
										searchable={false}
										options={resiSalesPropertyType}
										defaultValue={resiSalesPropertyType.find(obj => obj.value === propertyvalResi)}
										onChange={e => setPropertyValResi(e[0].value)}
										className=''
									/> */}
								</div>
								<div className="btn-wrapper form-btns">
									<div className="btn-wrap">
										<button className="btn" onClick={submitSearchResiSale}>For Sale</button>
									</div>
									<div className="btn-wrap">
										<button className="btn" onClick={submitSearchResiRent}>To Rent</button>
									</div>
								</div>
							</div>
							<div className="predictive-arelist-suggestion property-results p-0">
								<AreaListBox areas={areasArr} removeArea={removeArea} />
							</div>
						</form>
					</div>
				</Tab>	
				<Tab eventKey="newhomes" title="New Homes">
					<div className="form-wrap">
						<form onSubmit={submitHandler}>
							<div className="form-content">
								<div className="input-wrapper find-property-location-search location-search-newhomes">
									{/* <SearchResultInputBox areaVal={areaValNew} placeHolder={`Country, Region or Area`} department={"newhomes"} /> */}
									<SearchMultipleArea
										areaVal={areaVal} placeholder={"Location or Address"} setAreaVal={setAreaVal} setAreasList={setAreasList} handleMultiAreaFun={handleMultiAreaFun} autosuggestInputProps={{ className: 'react-autosuggest__input refine-autosuggest-input' }}
									/>
								</div>
								<div className="select-boxes find-property-dropdown">

								<PropertySelect
									propertyvalNew={propertyvalNew}
									newHomesSalesPropertyType={newHomesSalesPropertyType}
									setPropertyValNew={setPropertyValNew}	
									propType="New Homes"
									/>
									{/* <Select
										placeholder={propertyvalNew ? propertyvalNew : "Property Type"}
										searchable={false}
										options={newHomesSalesPropertyType}
										defaultValue={newHomesSalesPropertyType.find(obj => obj.value === propertyvalNew)}
										onChange={e => setPropertyValNew(e[0].value)}
										className=''
									/> */}
								</div>
								<div className="btn-wrapper form-btns">
									<div className="btn-wrap">
										<button className="btn" onClick={submitSearchNewSale}>For Sale</button>
									</div>
								</div>
							</div>
							<div className="predictive-arelist-suggestion property-results p-0">
								<AreaListBox areas={areasArr} removeArea={removeArea} />
							</div>
						</form>
					</div>
				</Tab>
				<Tab eventKey="commercial" title="Commercial">
					<div className="form-wrap">
						<form onSubmit={submitHandler}>
							<div className="form-content">
								<div className="input-wrapper find-property-location-search location-search-commercial">
									{/* <SearchResultInputBox areaVal={areaValComm} placeHolder={`Country, Region or Area`} department={"commercial"} /> */}
									<SearchMultipleArea
										areaVal={areaVal} placeholder={"Location or Address"} setAreaVal={setAreaVal} setAreasList={setAreasList} handleMultiAreaFun={handleMultiAreaFun} autosuggestInputProps={{ className: 'react-autosuggest__input refine-autosuggest-input' }}
									/>
								</div>
								<div className="select-boxes find-property-dropdown">
								<PropertySelect
									propertyvalComm={propertyvalComm}
									commercialSalesPropertyType={commercialSalesPropertyType}
									setPropertyValComm={setPropertyValComm}	
									propType="Commercial"
									/>
									{/* <Select
										placeholder={propertyvalComm ? propertyvalComm : "Property Type"}
										searchable={false}
										options={commercialSalesPropertyType}
										defaultValue={commercialSalesPropertyType.find(obj => obj.value === propertyvalComm)}
										onChange={e => setPropertyValComm(e[0].value)}
										className=''
									/> */}
								</div>
								<div className="btn-wrapper form-btns">
									<div className="btn-wrap">
										<button className="btn" onClick={submitSearchCommSale}>For Sale</button>
									</div>
									<div className="btn-wrap">
										<button className="btn" onClick={submitSearchCommRent}>To Rent</button>
									</div>
								</div>
							</div>
							<div className="predictive-arelist-suggestion property-results p-0">
								<AreaListBox areas={areasArr} removeArea={removeArea} />
							</div>
						</form>
					</div>
				</Tab>
				<Tab eventKey="auctions" title="Auctions">
					<div className="form-wrap">
						<form onSubmit={submitHandler}>
							<div className="form-content">
								<div className="input-wrapper find-property-location-search location-search-auction">
									{/* <SearchResultInputBox areaVal={areaValAuc} placeHolder={`Country, Region or Area`} department={"auction"} /> */}
									<SearchMultipleArea
										areaVal={areaVal} placeholder={"Location or Address"} setAreaVal={setAreaVal} setAreasList={setAreasList} handleMultiAreaFun={handleMultiAreaFun} autosuggestInputProps={{ className: 'react-autosuggest__input refine-autosuggest-input' }}
									/>
								</div>
								{/* <div className="select-boxes find-property-dropdown">
									<Select 
										placeholder={propertyvalAuc ? propertyvalAuc : "Property Type"}
										searchable={false}
										options={resiSalesPropertyType}
										defaultValue={resiSalesPropertyType.find(obj => obj.value === propertyvalAuc)}
										onChange={e => setPropertyValAuc(e[0].value)}
										className=''
									/>
								</div> */}
								<div className="btn-wrapper form-btns">
									<div className="btn-wrap">
										<button className="btn" onClick={submitSearchAucSale}>For Sale</button>
									</div>
									{/* <div className="btn-wrap">
										<button className="btn" onClick={submitSearchAucRent}>To Rent</button>
									</div> */}
								</div>
							</div>
							<div className="predictive-arelist-suggestion property-results p-0">
								<AreaListBox areas={areasArr} removeArea={removeArea} />
							</div>
						</form>
					</div>
				</Tab>
			</Tabs>

			{/* <ul className="search-nav">
				<li>
					<button className="active">Residential</button>
				</li>
				<li>
					<button>New Homes</button>
				</li>
				<li>
					<button>Commercial</button>
				</li>
				<li>
					<button>Auctions</button>
				</li>
			</ul> */}

			{/* <div className="form-wrap">
				<form onSubmit={submitHandler}>
					<div className="form-content">
						<div className="input-wrapper">
							<input type="text" className="form-control" placeholder="County, Region or Area" ref={locationRef}/>
						</div>
						<div className="select-boxes find-property-dropdown">
							<Select 
								placeholder={"Property Type"}
								searchable={false}
								options={resiSalesPropertyType}
								defaultValue={""}
								// onChange={e => setPropertyVal(e[0].value)}
								className=''
							/>
						</div>
						<div className="input-wrapper rotate-icon" onClick={changeColour} className={toggle ? "input-wrapper rotate-icon all-menu" : " input-wrapper rotate-icon "}>
							<button type="button" className="arrow-btn form-control">All Types</button>
							<div className={toggle ? "additional-inputs show " : "additional-inputs hide"}>
								<div className="btn-wrapper">
									<div className="btn-wrap">
										<button type="button" className="btn reset-btn">reset</button>
									</div>
									<div className="btn-wrap">
										<button type="button" className="btn">done</button>
									</div>
								</div>
								<div className="input-wrap">
									<input type="text" className="form-control" placeholder="Search" />
								</div>
								<div className="checkbox-group">
									<div className="left-block">
										<div className="checkbox-wrap">
											<label htmlFor="properties-all" className="checkbox-container">All Properties
												<input type="checkbox" id="properties-all"/>
												<span className="checkmark"></span>
											</label>
										</div>
										<div className="checkbox-wrap">
											<label htmlFor="properties-apartments" className="checkbox-container">Apartments
												<input type="checkbox" id="properties-apartments"/>
												<span className="checkmark"></span>
											</label>
										</div>
										<div className="checkbox-wrap">
											<label htmlFor="properties-bungalows" className="checkbox-container">Bungalows
												<input type="checkbox" id="properties-bungalows"/>
												<span className="checkmark"></span>
											</label>
										</div>
										<div className="checkbox-wrap">
											<label htmlFor="properties-house" className="checkbox-container">House
												<input type="checkbox" id="properties-house"/>
												<span className="checkmark"></span>
											</label>
										</div>
										
									</div>
									<div className="right-block">
										<div className="checkbox-wrap">
											<label htmlFor="properties-cottage" className="checkbox-container">Cottage
												<input type="checkbox" id="properties-cottage"/>
												<span className="checkmark"></span>
											</label>
										</div>
										<div className="checkbox-wrap">
											<label htmlFor="properties-sites" className="checkbox-container">Sites
												<input type="checkbox" id="properties-sites"/>
												<span className="checkmark"></span>
											</label>
										</div>
										<div className="checkbox-wrap">
											<label htmlFor="properties-land" className="checkbox-container">Land
												<input type="checkbox" id="properties-land"/>
												<span className="checkmark"></span>
											</label>
										</div>
										<div className="checkbox-wrap">
											<label htmlFor="properties-farm" className="checkbox-container">Farm
												<input type="checkbox" id="properties-farm"/>
												<span className="checkmark"></span>
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="btn-wrapper form-btns">
							<div className="btn-wrap">
								<button className="btn">For Sale</button>
							</div>
							<div className="btn-wrap">
								<button className="btn">To Rent</button>
							</div>
						</div>
					</div>
				</form>
			</div> */}
		</div>
	)
}

export default FindProperty;
