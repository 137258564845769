import React from 'react'
import Select from 'react-select';


const PropertySelect = (props) => {
  return (
    <>
        {props?.propType==="New Homes"?
        <Select
			placeholder={props.propertyvalNew ? props.propertyvalNew : "Property Type"}             
             isSearchable={false}
			onChange={e => props.setPropertyValNew(e.value)}            
            // value={props.newHomesSalesPropertyType?.find(obj => obj.value === props.propertyvalNew)}
            options={props.newHomesSalesPropertyType}
            classNamePrefix="select-property-select"
         />         
        :
        props?.propType==="Commercial"?
        <Select
        placeholder={props.propertyvalComm ? props.propertyvalComm : "Property Type"}             
         isSearchable={false}
        onChange={e => props.setPropertyValComm(e.value)}            
        // value={props.newHomesSalesPropertyType?.find(obj => obj.value === props.propertyvalNew)}
        options={props.commercialSalesPropertyType}
        classNamePrefix="select-property-select"
     />  
        :
        <Select
			placeholder={props.propertyvalResi ? props.propertyvalResi : "Property Type"}             
             isSearchable={false}
			onChange={e => props.setPropertyValResi(e.value)}            
            // value={props.resiSalesPropertyType.find(obj => obj.value === props.propertyvalResi)}
            options={props.resiSalesPropertyType}
            classNamePrefix="select-property-select"
         /> 
  }
    </>
  )
}

export default PropertySelect